import { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { setInLocalStorage } from "../../utils/storage";
import { supabase } from "../../supabase";

function SaveCourseId({ onSubmit }: { onSubmit: (id: string) => void }) {
  const [error, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  async function getUserById() {
    setLoading(true);
    const { data, error } = await supabase
      .from("users")
      .select("*")
      .eq("id", id.trim())
      .single();

    setLoading(false);
    if (error) {
      setErrorMessage("No data found!");
      return null;
    }

    setInLocalStorage("internshipId", id);
    onSubmit(id);

    return data;
  }

  const [id, setId] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    getUserById();
  };

  return (
    <Form
      onSubmit={handleSubmit}
      style={{
        fontSize: 12,
        width: "100%",
        maxWidth: 500,
        margin: "0 auto",
        border: "2px solid black",
        padding: 40,
        borderRadius: 12,
        background: "#F7F7F8",
      }}
    >
      <Form.Group controlId="formId">
        <Form.Label>
          <b>Enter the ID provided to you</b>
        </Form.Label>
        <Form.Control
          type="text"
          value={id}
          placeholder="e6b8dcb0-40e1-4a6c"
          maxLength={100}
          onChange={(e) => {
            setErrorMessage("");
            setId(e.target.value);
          }}
        />
        <div className="text-danger mt-1">{error}</div>
        <div className="mt-3">
          <b>Don't have an id? Request and </b>
          <a href="https://forms.gle/1uuQ1CJ3uh2czmmD9" target="_blank">
            <b>Join Now</b>
          </a>
        </div>
      </Form.Group>
      <Button
        variant="dark"
        type="submit"
        size="sm"
        className="mt-3 w-100"
        disabled={!id || isLoading}
      >
        {isLoading ? "Please wait..." : "Submit"}
      </Button>
    </Form>
  );
}

export default SaveCourseId;

import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./styles.css";

const LIST_OF_COURSES = [
  {
    id: "PM",
    title: "Product Management",
    description:
      "Master the fundamentals of product management during college and stand out from the crowd.",
  },
];

function CourseList() {
  return (
    <Container
      fluid
      className="p-4"
      style={{ height: "100vh", background: "#F7F7F8" }}
    >
      <div style={{ width: "100%", maxWidth: 700, margin: "0 auto" }}>
        <h1 className="display-6 mb-3 text-center">Available Courses</h1>
        <Row className="justify-content-center align-items-center h-100">
          <Col xs={12} sm={10} md={8} lg={6}>
            {LIST_OF_COURSES.map((course) => {
              return (
                <Link
                  to={`/course-overview/${course.id}`}
                  style={{ textDecoration: "none" }}
                  key={course.id}
                >
                  <Card className="mb-4 list-course">
                    <Card.Body>
                      <b>{course.title}</b>
                      <p className="mt-2 small">
                        <i>{course.description}</i>
                      </p>
                      <div className="text-end small text-decoration-underline">
                        Click To Start
                      </div>
                    </Card.Body>
                  </Card>
                </Link>
              );
            })}
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default CourseList;

import { Routes, Route } from "react-router-dom";
import CourseOverview from "./pages/CourseOverview";
import HomeIntro from "./pages/HomeIntro";
import CourseList from "./pages/CourseList";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeIntro />} />
      <Route path="/course-list" element={<CourseList />} />
      <Route path="/course-overview/:id" element={<CourseOverview />} />
    </Routes>
  );
}

export default App;

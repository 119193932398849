import { useEffect, useState } from "react";

import {
  BookmarkCheckFill,
  Check2Circle,
  List,
  X,
} from "react-bootstrap-icons";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Card,
  ProgressBar,
  Button,
} from "react-bootstrap";

import { CourseData } from "../../models/course";

import { getFromLocalStorage, setInLocalStorage } from "../../utils/storage";
import { isMobileDevice, scrollToTop } from "../../utils/device";

import PM_COURSE from "../../course-assets/PM.json";
import "./styles.css";
import { isYouTubeLink } from "../../utils/func";
import SaveCourseId from "../SaveCourseId";

let course: CourseData = PM_COURSE as CourseData;

const CourseOverview = () => {
  // const { id } = useParams();
  // if (id === "PM") {
  //   course = PM_COURSE as CourseData;
  // }
  const [isInternshipIdRequired, setInternshipIdReqStatus] = useState(
    !getFromLocalStorage("internshipId")
  );
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);
  //Always visible on desktop
  const [moduleVisible, toggleModuleSection] = useState(!isMobileDevice());
  const selectedModuleData = course.modules[selectedTopicIndex];
  const totalModules = course.modules.length;

  const checkModuleProgressStatus = (id: string): number => {
    if (getFromLocalStorage(id) === "STARTED") {
      return 50;
    }
    if (getFromLocalStorage(id) === "COMPLETED") {
      return 100;
    }
    return 0;
  };

  const isChapterCompleted = (id: string): boolean =>
    getFromLocalStorage(id) === "COMPLETED";

  const onMarkComplete = (id: string) => {
    setInLocalStorage(id, "COMPLETED");
    if (selectedTopicIndex === totalModules - 1) {
      alert(
        "Please submit the task. We will review and send you the internship certificate."
      );
      return;
    }
    scrollToTop();
    setSelectedTopicIndex(selectedTopicIndex + 1);
  };

  useEffect(() => {
    if (selectedTopicIndex === 0 && !isChapterCompleted(`${course.id}_1001`)) {
      setInLocalStorage(`${course.id}_1001`, "STARTED");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!course || !selectedModuleData) return <div>Course not found</div>;

  return (
    <Container fluid className="my-4">
      <h1 className="heading-course">
        <BookmarkCheckFill /> {course.name}
      </h1>
      <Row className="mt-4">
        <Col md={4} sm={12} className="mb-3">
          <Button
            className="d-md-none mb-3 d-flex align-items-center px-0"
            variant="link"
            size="sm"
            onClick={() => toggleModuleSection((prev) => !prev)}
          >
            <>
              {moduleVisible ? <X /> : <List />}
              <small>{moduleVisible ? "Hide Modules" : "Show Modules"}</small>
            </>
          </Button>
          {moduleVisible ? (
            <ListGroup>
              {course.modules.map(({ module, name, id }, index) => (
                <ListGroup.Item
                  key={index}
                  action
                  onClick={() => {
                    if (!getFromLocalStorage("internshipId")) return;
                    setSelectedTopicIndex(index);
                    if (isMobileDevice()) toggleModuleSection(false);
                    if (isChapterCompleted(`${course.id}_${id}`)) return;
                    setInLocalStorage(`${course.id}_${id}`, "STARTED");
                  }}
                  className={
                    selectedTopicIndex === index
                      ? "selected-list-group-item"
                      : ""
                  }
                >
                  <div className="module-count">{module}</div>
                  <div className="course-label">{name}</div>
                  <ProgressBar
                    now={checkModuleProgressStatus(`${course.id}_${id}`)}
                    className="mt-2"
                    variant="success"
                    style={{ height: 8 }}
                  />
                  {isChapterCompleted(`${course.id}_${id}`) ? (
                    <div className="completed-text">
                      You made it! Completed the chapter.
                    </div>
                  ) : null}
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : null}
        </Col>

        <Col md={8} sm={12}>
          {isInternshipIdRequired ? (
            <SaveCourseId onSubmit={() => setInternshipIdReqStatus(false)} />
          ) : (
            <Card>
              <Card.Body>
                <div className="module-count d-flex justify-content-between">
                  <span>{selectedModuleData.module}</span>
                  <span className="text-secondary">
                    Requires{" "}
                    <b>
                      {selectedModuleData.averageDaysRequiresToFinish || 1}{" "}
                      day(s)
                    </b>
                  </span>
                </div>
                <div className="module-heading">{selectedModuleData.name}</div>

                <Card.Text className="mt-1 text-secondary small">
                  {selectedModuleData.content.description}
                </Card.Text>

                {selectedModuleData.content.videoLink ? (
                  <div className="mt-4 video-container">
                    {isYouTubeLink(selectedModuleData.content.videoLink) ? (
                      <div className="hide-yt" />
                    ) : null}
                    <iframe
                      src={selectedModuleData.content.videoLink}
                      title={selectedModuleData.id}
                    ></iframe>
                  </div>
                ) : null}

                {selectedModuleData.content.html ? (
                  <div
                    style={{ fontSize: 14 }}
                    dangerouslySetInnerHTML={{
                      __html: selectedModuleData.content.html,
                    }}
                  ></div>
                ) : null}

                {selectedModuleData.task ? (
                  <div>
                    <hr />
                    <b className="module-heading">Task:</b>
                    <div className="text-secondary small">
                      {selectedModuleData.task.description}
                    </div>
                    {selectedModuleData.task.link ? (
                      <a
                        href={selectedModuleData.task.link}
                        target="_blank"
                        className="small"
                        rel="noreferrer"
                      >
                        Click Here
                      </a>
                    ) : null}
                  </div>
                ) : null}

                {
                  <div className="text-end mt-4">
                    <Button
                      variant="link"
                      size="sm"
                      style={{ color: "green" }}
                      onClick={() =>
                        onMarkComplete(`${course.id}_${selectedModuleData.id}`)
                      }
                    >
                      <>
                        <Check2Circle /> Mark Complete
                      </>
                    </Button>
                  </div>
                }
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CourseOverview;
